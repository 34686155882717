import React, { useState } from 'react'
import useDownloader from 'react-use-downloader'; 
const Uploadcard = ({item  , handleOpen}) => {
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();
 
  return (
     <>     
      <div   className="image">
      <img onClick={()=>handleOpen(item?.image_url)} src={`${item?.image_url}`} height="200" width={200} alt="upload" />
      <p className='img__title'>{item?.name}</p>
      <button  onClick={() => download(item?.image_url, item?.name)} className='save__btn' >
      Скачать
      </button>
    </div>
     </>
  )
}

export default Uploadcard
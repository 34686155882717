import React from 'react'

const Card = ({ deleteHandler , item}) => {
  return (
    <div  className="selected__imges">
    {/* <img src={item?.img} height="200" width={200} alt="upload" /> */}
    <p className='img__title'>{item?.file?.name}</p>
    <button className='delete__btn' onClick={() => deleteHandler(item?.img)}>
    Удалить
    </button>
  </div>
  )
}

export default Card
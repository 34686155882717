import React from 'react'
import laodimg from '../../assets/Images/Rolling-1s-200px — копия.svg'
const Loading = () => {
  return (
    <div className='laoading'>
      <img width={50} src={laodimg} alt="laoding" />
    </div>
  )
}

export default Loading
import './modal.css'
const Modal = ({children , handleClose}) => {
    window.onclick = function(event) {
         if(event.target.id === "myModal"){
            handleClose()
         }
      }
  return (
    <div id="myModal" class="modal">
  <div class="modal-content">
    <span onClick={handleClose} class="close">&times;</span>
    {
        children
    }
  </div>

</div>
  )
}

export default Modal
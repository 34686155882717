import React, { useState } from 'react'
import './assets/main.css'
import Card from './components/Card/Card';
import Uploadcard from './components/Uploadcard/Uploadcard';
import Loading from './components/Loading/Loading';
import axios from 'axios'
import Modal from './components/Modal/Modal';
const App = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [loading , setLoading] = useState(false)
  const [modal , setModal] = useState(false)
  const [imgurl , setImgurl] = useState("")
  const hanleClose =()=>{
    setModal(false)
    setImgurl("")
  }
  const handleOpen =(url) =>{
    setModal(true)
    setImgurl(url)
  }

  const onSelectFile = (event) => {
    setSelectedImages([...selectedImages ,{file : event.target.files[0] , img : URL.createObjectURL(event.target.files[0])} ])
    console.log(selectedImages)
    event.target.value = ""
  }

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e.img !== image));
    URL.revokeObjectURL(image);
  }
  const handleSubmit = () => {
        setLoading(true)
       const form = new FormData()
       for(let i = 0; i < selectedImages.length; i++) {
         form.append("image_files", selectedImages[i].file)
       }
    
        axios.post("https://api.evotren3.ru/photo/generate/api/" ,form).then((res)=>{
           console.log(res.data)
           setUploads([...uploads , ...res?.data])
           if(res.status === 200){
            setLoading(false)
            setSelectedImages([])
           }
        }).catch((err)=>{
          console.log(err)
        })
    }
  return (
    <section className='main-section'>
      {modal && <Modal handleClose={hanleClose}>
        <img className='modalimg' src={imgurl} alt="imgurl" />
        </Modal>}
      {loading && <Loading/>}
      <div className="container">
      <div className='uploadimg__wrapper'>
     <div>
     <label>
      + Добавить фото
      <br />
      <span>up to 10 images</span>
      <input
        type="file"
        name="images"
        onChange={onSelectFile}
        multiple
        accept="image/png , image/jpeg, image/webp"
      />
    </label>
    <br />

    <input type="file" multiple />

    {
      (selectedImages.length > 10 ? (
        <p className="error">
          Вы не можете загрузить более 10 изображений! <br />
          <span>
            please delete <b> {selectedImages.length - 10} </b> of them{" "}
          </span>
        </p>
      ) : (
        <button
          className="upload-btn"
          onClick={() =>handleSubmit()}
        >
             Наложить сетку 
        </button>
      ))}
     </div>
     <div>
      <h3 className="selected__images">
      Загруженные фото
      </h3>
     {selectedImages &&
        selectedImages.map((item, index) => (
             <Card  key={index} item={item} deleteHandler={deleteHandler}/>
        ))}
     </div>
     </div>

    <div className="images">
        {
          uploads &&
          uploads.map((item, index) => (
            <Uploadcard handleOpen={handleOpen} item={item} key={index}/>
          ))
        }
    </div>
   
      </div>
  </section>
  )
}

export default App
